import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { ErrorComponent } from 'src/app/shared/components/dialogs/error/error.component';
import { WarningComponent } from '../components/dialogs/warning/warning.component';
import { ToastType } from '../enums/ToastType.enum';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  private toastedSource: Subject<any> = new Subject<any>();
  toasted$ = this.toastedSource.asObservable();

  constructor(public dialog: MatDialog) { }

  success(message: string) {
    this.toastedSource.next({type: ToastType.SUCCESS, message});
  }

  error(payload: any) {
    this.dialog.open(ErrorComponent, {
      width: '700px',
      data: payload
    });
  }

  warning(title: string, message: string) {
    this.dialog.open(WarningComponent, {
      width: '700px',
      data: {
        title,
        message,
      }
    });
  }

  invalidSession(err: any) {
    console.warn('error: ', err);
    this.error(
      {
        message: 'Your session is no longer valid. Try the following steps to restore your session',
        response: [
          'Refresh your browser',
          'Log out and log back in'
        ]
      }
    );
  }

  toast(payload: any) {
    this.toastedSource.next(payload);
  }

}
