import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { StorageService } from 'src/app/shared/services/storage.service';
import { ModelComparisonType } from 'src/app/site-manager/components/dialogs/audit/audit.component';
import { EntityType } from 'src/app/site-manager/enums/EntityTypes.enum';
import { BatchTagDelete } from 'src/app/site-manager/models/BatchDelete.model';
import { BatchEntityCollection } from 'src/app/site-manager/models/BatchEntityCollection.model';
import { Equip } from 'src/app/site-manager/models/Equip.model';
import { FloorDto } from 'src/app/site-manager/models/Floor.model';
import { Space } from 'src/app/site-manager/models/Space.model';
import { TagBatch } from 'src/app/site-manager/models/TagBatch.model';
import { TagTree } from 'src/app/site-manager/models/TagTree.model';
import { Version } from 'src/app/site-manager/models/Version.model';
import { ZoneDto } from 'src/app/site-manager/models/Zone.model';
import { ConfigurationService } from './configuration.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private errorSource: Subject<any> = new Subject<any>();
  error$ = this.errorSource.asObservable();

  constructor(public http: HttpClient,
    private configService: ConfigurationService,
    private storage: StorageService) { }

  /**
   * Get the logged in user object
   * @returns Logged in user info
   */
  getUser() {
    const url = `${this.configService.get('caretakerUrl')}/token/userinfo`;
    return this.get(url, { headers: this.jsonTypeHeaders });
  }

  get baseUrl() {
    return this.configService.get('siteManagerServiceUrl');
  }

  get jsonTypeHeaders() {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.storage.bearerToken}`,
      '75F-Application': 'SITE_MANAGER_UI',
    });
  }

  get(url: string, options?: any): Observable<any> {
    return this.http.get(url, options).pipe(
      catchError((err) => this.handleHttpError(err))
    );
  }

  post(url: string, payload: any, options?: any): Observable<any> {
    return this.http.post(url, payload, options).pipe(
      catchError((err) => this.handleHttpError(err))
    );
  }

  put(url: string, payload: any, options?: any): Observable<any> {
    return this.http.put(url, payload, options).pipe(
      catchError((err) => this.handleHttpError(err))
    );
  }

  delete(url: string, options?: any): Observable<any> {
    return this.http.delete(url, options).pipe(
      catchError((err) => this.handleHttpError(err))
    );
  }

  private handleHttpError(err: HttpErrorResponse): Observable<any> {
    console.error({ message: `There was an error processing your request. Please try again. (error code: ${err.status})` });

    this.errorSource.next();

    return throwError(err);
  }

  // TODO: This must be updated to consume versions in [Story 18560]
  getModelDiff(siteId: string, leftModelId: string, rightModelId: string, comparisonType: ModelComparisonType) {
    const url = `${this.baseUrl}/sites/${siteId}/diffs?leftModelId=${leftModelId}&rightModelId=${rightModelId}&comparisonType=${comparisonType}`;
    return this.get(url, { headers: this.jsonTypeHeaders });
  }

  createZone(payload: Space): Observable<any> {
    let zone: ZoneDto = {
      name: payload.name,
      siteRef: payload.siteRef,
      floorRef: payload.floorRef,
      createdBySiteManager: true,
    };
    const url = `${this.baseUrl}/sites/${zone.siteRef}/zones`;
    return this.post(url, zone, { headers: this.jsonTypeHeaders, responseType: 'text' });
  }

  updateZone(spaceId: string, payload: Space, scheduleRef: string, bacnetId: number, bacnetType: string, createdBySiteManager: boolean): Observable<any> {
    let zone: ZoneDto = {
      id: spaceId,
      name: payload.name,
      siteRef: payload.siteRef,
      floorRef: payload.floorRef,
      ccuRef: payload.ccuRef,
      scheduleRef,
      bacnetId,
      bacnetType,
      createdBySiteManager
    };
    const url = `${this.baseUrl}/sites/${zone.siteRef}/zones/${spaceId}`;
    return this.put(url, zone, { headers: this.jsonTypeHeaders, responseType: 'text' });
  }

  createFloor(payload: Space): Observable<any> {
    let floor: FloorDto = {
      name: payload.name,
      siteRef: payload.siteRef,
      floorNum: 0,
      orientation: 0,
      createdBySiteManager: true,
    };
    const url = `${this.baseUrl}/sites/${floor.siteRef}/floors`;
    return this.post(url, floor, { headers: this.jsonTypeHeaders, responseType: 'text' });
  }

  updateFloor(spaceId: string, payload: Space, floorNum: number, orientation: number, createdBySiteManager: boolean): Observable<any> {
    let floor: FloorDto = {
      id: spaceId,
      name: payload.name,
      siteRef: payload.siteRef,
      floorNum,
      orientation,
      createdBySiteManager,
    };
    const url = `${this.baseUrl}/sites/${floor.siteRef}/floors/${spaceId}`;
    return this.put(url, floor, { headers: this.jsonTypeHeaders, responseType: 'text' });
  }

  deleteEntity(type: EntityType, id: string) {
    const url = `${this.baseUrl}/${this.getApiTarget(type)}/${id}`;
    return this.delete(url, { headers: this.jsonTypeHeaders, responseType: 'text' });
  }

  deleteEntities(batch: BatchEntityCollection) {
    const url = `${this.baseUrl}/sites/${batch.siteRef}/batch/entities`;
    return this.delete(url, { headers: this.jsonTypeHeaders, body: { entityRefs: batch.entities.map(x => x.id) }, responseType: 'text' });
  }

  deleteTags(batch: BatchTagDelete) {
    const url = `${this.baseUrl}/sites/${batch.siteRef}/batch/tags`;
    return this.delete(url, { headers: this.jsonTypeHeaders, body: batch, responseType: 'text' });
  }

  addTagBatch(batch: TagBatch) {
    const url = `${this.baseUrl}/sites/${batch.siteRef}/batch/tags`;
    return this.post(url, batch, { headers: this.jsonTypeHeaders, responseType: 'text' });
  }

  getApiTarget(type: EntityType) {
    switch (type) {
      case EntityType.FLOOR:
        return 'floors';
      case EntityType.ZONE:
        return 'zones';
      case EntityType.EQUIP:
        return 'equips';
      case EntityType.POINT:
        return 'points';
      default:
        return 'null';
    }
  }

  postEquip(payload: Equip) {
    if (payload.id) {
      const url = `${this.baseUrl}/sites/${payload.siteRef}/equips/${payload.id}`;
      return this.put(url, payload, { headers: this.jsonTypeHeaders, responseType: 'text' });
    } else {
      const url = `${this.baseUrl}/sites/${payload.siteRef}/equips`;
      return this.post(url, payload, { headers: this.jsonTypeHeaders, responseType: 'text' });
    }
  }

  getEquipById(siteId: string, equipId: string) {
    const url = `${this.baseUrl}/sites/${siteId}/equips/${equipId}`;
    return this.get(url, { headers: this.jsonTypeHeaders });
  }
  
  // DOMAIN MODELER DEFINITIONS

  /**
   * Get model by its id and version
   * @returns Model
   */

  getModel(id: string, version?: Version) {
    let url = `${this.baseUrl}/definitions/models/${id}`;
    if (version != null) url += `?version=${version.major}.${version.minor}.${version.patch}`;
    return this.get(url, { headers: this.jsonTypeHeaders });
  }

  /**
   * Get models by namespace
   * @returns List of models in specified namespace
   */
  getModels(namespace: string) {
    const url = `${this.baseUrl}/definitions/models/${namespace}/list`;
    return this.get(url, { headers: this.jsonTypeHeaders });
  }

  /**
   * Get all points or a limited list of points in the system
   * @returns List of points
   */

  getPoints() {
    const url = `${this.baseUrl}/definitions/points`;
    return this.get(url, { headers: this.jsonTypeHeaders });
  }

  postBatchPoints(siteRef: string, payload: any) {
    const url = `${this.baseUrl}/sites/${siteRef}/batch/points`;
    return this.put(url, payload, { headers: this.jsonTypeHeaders, responseType: 'text' });
  }

  /**
   * Get hydrated point by ID
   * @returns point
   */

  getPoint(pointId: string) {
    const url = `${this.baseUrl}/definitions/points/${pointId}?hydrate=true`;
    return this.get(url, { headers: this.jsonTypeHeaders });
  }

  /**
   * Get all tags from domain modeler
   * @returns List of tags
   */

  getTags() {
    const url = `${this.baseUrl}/definitions/tags`;
    return this.get(url, { headers: this.jsonTypeHeaders });
  }

  getTagTree(batch: BatchTagDelete): Observable<TagTree> {
    const url = `${this.baseUrl}/sites/${batch.siteRef}/batch/tags/tree`;
    return this.post(url, batch, { headers: this.jsonTypeHeaders });
  }

  /**
   * Get list of published versions for a model
   * @param modelId ID of modelDef
   */
  getModelVersionList(modelId: String, namespace: String) {
    const url = `${this.baseUrl}/definitions/models/${namespace}/${modelId}/list`;
    return this.get(url, { headers: this.jsonTypeHeaders });
  }

  /**
   * Get all units under the specified dimensions
   * @param dimensions A list of haystack dimensions
   * @returns A flattened list of units
   */
  getUnits(dimensions: string[]) {
    const url = `${this.baseUrl}/definitions/units?dimensions=${dimensions.join(',')}`;
    return this.get(url, { headers: this.jsonTypeHeaders });
  }
}