import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  private searchSource: Subject<any> = new Subject<any>();
  search$ = this.searchSource.asObservable();

  private limitedSearchScopeSource: Subject<any> = new Subject<any>();
  limitedSearch$ = this.limitedSearchScopeSource.asObservable();

  search(value: string) {
    this.searchSource.next(value);
  }

  limitedScopeSearch(value: string) {
    this.limitedSearchScopeSource.next(value);
  }
  

}
