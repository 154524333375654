import { Injectable } from '@angular/core';
import { StorageKeys } from '../enums/StorageKeys.enum';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  /**
   * Clear local and session storage item for the key supplied
   * @param key Key to clear
   */
  clearItem(key: string) {
    localStorage.removeItem(key);
    sessionStorage.removeItem(key);
  }

  set(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  get(key: string) {
    return localStorage.getItem(key);
  }

  /**
   * Get the current bearer-token
   */
  get bearerToken() {
    return localStorage.getItem(StorageKeys.BEARER_TOKEN);
  }

  /**
   * Store the bearer token in local storage
   */
  set bearerToken(token: string) {
    localStorage.setItem(StorageKeys.BEARER_TOKEN, token);
  }

  /**
   * Get the current route
   */
  get route() {
    return sessionStorage.getItem(StorageKeys.ROUTE);
  }

  /**
   * Set the current route in local storage
   */
  set route(route: string) {
    sessionStorage.setItem(StorageKeys.ROUTE, route);
  }

}
