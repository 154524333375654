import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HDict, HGrid, HRef, HStr, ZincReader } from '@j2inn/haystack-core';
import { Observable, of, Subject } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { StorageService } from 'src/app/shared/services/storage.service';
import { EntityType } from 'src/app/site-manager/enums/EntityTypes.enum';
import { AppVersion } from 'src/app/site-manager/models/AppVersion.model';
import { Site } from 'src/app/site-manager/models/Site.model';
import { SiteEntity } from 'src/app/site-manager/models/SiteEntity.model';
import { ConfigurationService } from './configuration.service';

@Injectable({
  providedIn: 'root'
})
export class SiloService {

  private reloadEntitiesSource: Subject<string> = new Subject<string>();
  reloadEntities$ = this.reloadEntitiesSource.asObservable();

  private reloadSiteSource: Subject<string> = new Subject<string>();
  reloadSite$ = this.reloadSiteSource.asObservable();

  private selectSiteSource: Subject<string> = new Subject<string>();
  selectSite$ = this.selectSiteSource.asObservable();

  activeSite: Site;
  selectedSiteId: string;
  siloUrl: string;

  changePersistenceCompatibleCcuVersion: AppVersion;
  changePersistenceEnabled: boolean = false;

  constructor(
    private http: HttpClient,
    private configService: ConfigurationService,
    private storage: StorageService
  ) {
    this.siloUrl = this.configService.get('siloUrl');
    this.changePersistenceCompatibleCcuVersion = AppVersion.fromString(this.configService.get('changePersistenceCompatibleCcuVersion'))
  }

  setHttpParams(params) {
    let httpParams = new HttpParams();
    if (params) {

      Object.keys(params).forEach((key, index) => {
        if (params[key] instanceof Object) {
          httpParams = httpParams.append(key, JSON.stringify(params[key]));
        } else {
          httpParams = httpParams.append(key, params[key]);
        }
      });
    }
    return httpParams;
  }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error);
  }

  get authOnlyHeaders() {
    return new HttpHeaders({
      'Content-Type': 'text/zinc',
      'Accept': 'text/zinc',
      'Authorization': `Bearer ${this.storage.bearerToken}`
    });
  }

  filterQuery(query: string) {
    const filterGrid = new HGrid({ columns: [{ name: 'filter' }], rows: [{ filter: query }] });
    return this.http.post(`${this.siloUrl}/v2/read`, filterGrid.toZinc(), { headers: this.authOnlyHeaders, responseType: 'text' }).pipe(
      map(result => this.readZinc(result)),
      catchError(this.handleError)
    );
  }

  readByIdQuery(entityId: string) {
    const readGrid = new HGrid({ columns: [{ name: 'id' }], rows: [new HDict({ id: HRef.make(entityId) })] });
    return this.http.post(`${this.siloUrl}/v2/read`, readGrid.toZinc(), { headers: this.authOnlyHeaders, responseType: 'text' }).pipe(
      map(result => this.readZinc(result)),
      catchError(this.handleError)
    );
  }

  getSiteEntities(siteId: string): Observable<SiteEntity[]>{
    return this.filterQuery(`siteRef==@${siteId}`)
    .pipe(mergeMap((entities) => this.mapEntities(entities)))
  }

  navIdQuery(navId: string) {
    const navGrid = new HGrid({ columns: [{ name: 'navId' }], rows: [new HDict({ navId: HRef.make(navId) })] });
    return this.http.post(`${this.siloUrl}/v2/nav`, navGrid.toZinc(), { headers: this.authOnlyHeaders, responseType: 'text' }).pipe(
      map(result => this.readZinc(result)),
      catchError(this.handleError)
    );
  }

  pointWriteMany(requestGrid: HGrid): Observable<HGrid> {
    return this.http.post(`${this.siloUrl}/v2/pointWriteMany`, requestGrid.toZinc(), { headers: this.authOnlyHeaders, responseType: 'text' }).pipe(
      map(result => this.readZinc(result).toGrid())
    )
  }

  private readZinc(zinc: string) {
    return ZincReader.readValue(zinc.replace(/\\/g, "\\\\"));
  }

  public getSites(): Observable<Site[]> {
    return this.filterQuery('site')
      .pipe(mergeMap((entities) => this.mapSites(entities)))
  }

  private mapSites(entities): Observable<Site[]> {
    return of(entities.map(entity => {
      return {
        id: (entity.get('id') as HRef).dis,
        name: entity.get('dis')?.toString(),
        address: `${entity.get('geoAddr')}, ${entity.get('geoCity')}, ${entity.get('geoState')}, ${entity.get('geoCountry')} - ${entity.get('geoPostalCode')}`,
        timeZone: entity.get('tz')?.toString(),
        facilityManagerEmail: entity.get('fmEmail')?.toString()
      }
    }).reverse())
  }

  /**
   * Queries for all app version diagnostic points and performs a /pointWriteMany to get the current installed version
   * for every CCU on the site. Then versions are compared against the configured minimum compatible version for entity change persistence.
   * Change persistence is then globally disabled if any CCU version is below/older than the configured value
   * 
   * @param siteId 
   * @returns Observable<boolean> 
   */
  public getAndEvaluateCcuVersions(siteId: String): Observable<boolean> {
    return new Observable<boolean>(subscriber => {
      if (!siteId) {
        console.error('Attempted to evaluate CCU app cersions before active site was set.');
        return subscriber.next(false);
      }

      this.changePersistenceEnabled = false;

      this.filterQuery(`siteRef == @${siteId} and diag and point and app and version`)
        .subscribe((diagPoints) => {
          if (!diagPoints?.length) {
            return subscriber.next(false);
          }

          const pointWriteRequestGrid = new HGrid({ 
            columns: [{ name: 'id' }], 
            rows: diagPoints.map(point => ({ id: point.id })) 
          });

          this.pointWriteMany(pointWriteRequestGrid)
            .subscribe((pointWriteResponseGrid) => {
              this.changePersistenceEnabled = this.isMinAppVersionGreaterThan(pointWriteResponseGrid, this.changePersistenceCompatibleCcuVersion);
              subscriber.next(this.changePersistenceEnabled);
            });
        });
    })
  }
  
  /**
   * Given a pointWrite many response, which must contain appVersion priority array values, this determines
   * whether each appVersion in the response is newer than the configured minimum app version.
   * 
   * @param pointWriteResponseGrid PointWriteMany response for appVersion diagnostic points
   * @param minAppVersion Minimum version to compare against
   * @returns 
   */
  isMinAppVersionGreaterThan(pointWriteResponseGrid: HGrid<HDict>, minAppVersion: AppVersion): boolean {
    if (!pointWriteResponseGrid?.length) {
      return false;
    }

    for(let row of pointWriteResponseGrid) {
      const appVersionText = row.get('data')?.toList()?.get(0)?.toDict()?.get('val')?.toString();
      if (!appVersionText) {
        console.warn('Failed to determine CCU app version from empty diag point value. Change persistence may be incorrectly disabled.')
        return false;
      }

      const appVersion = AppVersion.fromString(appVersionText);
      if (minAppVersion.isNewerThan(appVersion)) {
        return false;
      }
    }

    return true;
  }

  public getEntities(navId: string) {
    return this.navIdQuery(`@${navId}`).pipe(
      mergeMap((entities) => this.mapEntities(entities))
    );
  }

  mapEntities(entities): Observable<any> {
    return of(entities.map(entity => this.mapEntity(entity)).sort(this.compare));
  }

  mapEntity(dict: HDict): SiteEntity {
    let entity: SiteEntity = {
      id: (dict.get('id') as HRef).dis,
      type: this.getEntityType(dict),
      name: dict.get('dis').toString(),
      tags: this.getTagsFromEntity(dict),
      siteRef: (dict.get('siteRef') as HRef)?.dis,
      floorRef: (dict.get('floorRef') as HRef)?.dis,
      roomRef: (dict.get('roomRef') as HRef)?.dis,
      ccuRef: (dict.get('ccuRef') as HRef)?.dis,
      createdBySiteManager: this.wasCreatedBySiteManager(dict),
      isBuildingEquip: this.isBuildingEquip(dict),
      sortOrder: this.getSortOrder(dict),
      canModify: this.canModify(dict),
      canAttachEntity: this.canAttachEntity(dict),
    }
    if (entity.type == 'equip') {
      entity.sourceId = (dict.get('sourceModel') as HStr)?.value
    }
    if (entity.type == 'point') {
      entity.sourceId = (dict.get('sourcePoint') as HStr)?.value
    }
    return entity;
  }
  
  /**
   * The entity itself can be modified/renamed
   * @param entity 
   * @returns Boolean indicating that this entity can be modified 
   */
  private canModify(entity) {
    if (this.isChildEquip(entity)) {
      return false;
    }

    if (this.changePersistenceEnabled && (this.isFloor(entity) || this.isZone(entity))) {
      return true;
    }

    return (this.wasCreatedBySiteManager(entity) || this.isCCUEquip(entity)) && !this.isBuildingEquip(entity);
  }

  /**
   * Shared data includes data created by Site Manager and shared across multiple devices (CCUs).
   * Today, shared data includes:
   * - Building tuner
   * @param entity 
   * @returns Boolean indicating that this entity is shared 
   */
  public isBuildingEquip(entity) {
    return entity.has('tuner') && entity.has('equip');
  }

  /**
   * Users can modify (add points) to CCU created equips
   * @param entity 
   * @returns Boolean indicating the entity is a CCU created equip
   */
  private isCCUEquip(entity) {
    return this.isEquip(entity) && !this.wasCreatedBySiteManager(entity);
  }

  /**
   * Users cannot directly modify child-equips
   * @param entity 
   * @returns Boolean indicating the entity is a child-equip
   */
  private isChildEquip(entity) {
    return this.isEquip(entity) && entity.has("equipRef");
  }

  /**
   * Multiple entities can be attached to this entity
   * @param entity 
   * @returns Boolean indicating that this entity can attach entities
   */
  private canAttachEntity(entity) {
    return this.isFloor(entity) || (this.wasCreatedBySiteManager(entity) && this.isZone(entity));
  }

  isFloor(entity) {
    return this.getEntityType(entity) === EntityType.FLOOR;
  }

  isZone(entity) {
    return this.getEntityType(entity) === EntityType.ZONE;
  }

  isEquip(entity) {
    return this.getEntityType(entity) === EntityType.EQUIP;
  }

  isPoint(entity) {
    return this.getEntityType(entity) === EntityType.POINT;
  }

  isSchedule(entity) {
    return this.getEntityType(entity) === EntityType.SCHEDULE;
  }

  wasCreatedBySiteManager(entity: HDict) {
    const createdBy = entity.get('createdByApplication')?.toString();
    return createdBy === 'SITE_MANAGER';
  }
  
  /**
   * Get all of the marker tags from an entiity
   * @param entity 
   * @returns array of tags
   */
  getTagsFromEntity(entity: any) {
    const tags = [];
    entity.keys.forEach(key => {
      tags.push({
        key: key,
        type: entity.get(key).getKind(),
        value: entity.get(key)?.value
      });
    });
    return tags;
  }

  /**
   * Sort rows based on entity type
   * @param entity
   * @returns number representing the position of the element
   */
  private getSortOrder(entity: any) {
    if (this.isSchedule(entity)) {
      return 1;
    } else if (this.isFloor(entity)) {
      return 2;
    } else if (this.isZone(entity)) {
      return 3;
    } else if (this.isEquip(entity)) {
      return 4;
    } else if (this.isPoint(entity)) {
      return 5;
    } else {
      return 6;
    }
  }

  private getEntityType(entity: HDict) {
    if (entity.has('floor')) {
      return EntityType.FLOOR;
    } else if (entity.has('room')) {
      return EntityType.ZONE;
    } else if (entity.has('equip')) {
      return EntityType.EQUIP;
    } else if (entity.has('point')) {
      return EntityType.POINT;
    } else if (entity.has('schedule')) {
      return EntityType.SCHEDULE;
    } else if (entity.has('device')) {
      return EntityType.DEVICE;
    } else {
      return null;
    }
  }

  reloadEntities(ref: string) {
    this.reloadEntitiesSource.next(ref);
  }
  
  reloadSite(ref: string) {
    this.reloadSiteSource.next(ref);
  }

  selectSite(ref: any) {
    this.selectSiteSource.next(ref);
  }

  compare(a, b) {
    if (a.sortOrder < b.sortOrder) {
      return -1;
    }
    if (a.sortOrder > b.sortOrder) {
      return 1;
    }
    return 0;
  }

}
