const VERSION_REGEX: RegExp = /^\d+\.\d+\.\d+$/;

export class AppVersion {
  major: number;
  minor: number;
  patch: number;

  constructor(major: number, minor: number, patch: number) {
    this.major = major;
    this.minor = minor;
    this.patch = patch;
  }

  isNewerThan(other: AppVersion): boolean {
    if (!other) {
      return false;
    }

    return this.major > other.major ||
      (this.major == other.major && this.minor > other.minor) ||
      (this.major == other.major && this.minor == other.minor && this.patch > other.patch)
  }

  toString(): string {
    return `${this.major}.${this.minor}.${this.patch}`
  }

  static fromString(versionText: string): AppVersion {
    if (!versionText || !versionText.length) {
      return null;
    }

    if (!VERSION_REGEX.test(versionText)) {
      console.warn(`Invalid App Version encountered: ${versionText}`)
      return null;
    }
    
    const [major, minor, patch] = versionText.split('.');
    return new AppVersion(parseInt(major), parseInt(minor), parseInt(patch))
  }
}