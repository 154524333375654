import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { delay, filter, tap } from 'rxjs/operators';
import { BaseWrapperDirective } from 'src/app/shared/classes/base-wrapper';
import { Toast } from 'src/app/shared/models/Toast.model';
import { ToastService } from 'src/app/shared/services/toast.service';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('detailExpand', [
      state('inactive', style({ bottom: '-90px' })),
      state('active', style({ bottom: '40px' })),
      transition('inactive <=> active', animate('400ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ]
})
export class ToastComponent extends BaseWrapperDirective implements OnInit {

  toastActive$: boolean;
  payload$: Toast;

  constructor(private toastService: ToastService) {
    super();
  }

  ngOnInit(): void {
    this.subs.sink = this.toastService.toasted$.pipe(
      filter(() => !this.toastActive$),
      tap((payload) => this.payload$ = payload),
      tap(() => this.toastActive$ = true),
      delay(3000),
      tap(() => this.toastActive$ = false),
      delay(500),
      tap(() => this.payload$ = null)
    ).subscribe();
  }

  get iconClass() {
    return this.payload$?.type || '';
  }

}
