import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ApiService } from '../../shared/services/api.service';
import { Permissions } from 'src/app/shared/enums/Permissions.enum';
import { ToastService } from 'src/app/shared/services/toast.service';
import { StorageService } from 'src/app/shared/services/storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  currentUser: any;

  private errorSource: Subject<any> = new Subject<any>();
  error$ = this.errorSource.asObservable();

  private sessionVerifiedSource: Subject<any> = new Subject<any>();
  sessionVerified$ = this.sessionVerifiedSource.asObservable();

  constructor(
    private apiService: ApiService,
    private storageService: StorageService,
    private toast: ToastService) { }
  
  validateToken(token: string) {
    this.storageService.bearerToken = token;
    this.apiService.getUser().subscribe(response => {
      this.validateUser(response);
    }, error => {
        this.notifyOfError(error);
    });
  }
  
  validateUser(response: any) {
    this.currentUser = response;
    this.sessionVerifiedSource.next(this.isSupport(response));
  }

  isSupport(response: any) {
    return response[Permissions.SUPPORT] ? true : false;
  }

  notifyOfError(err: any) {
    this.toast.invalidSession(err);
  }
}
