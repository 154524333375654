import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { debounceTime, tap } from 'rxjs/operators';
import { BaseWrapperDirective } from 'src/app/shared/classes/base-wrapper';
import { MultiSelectGridItem } from 'src/app/shared/models/MultiSelectGridItem.model';
import { SearchService } from 'src/app/shared/services/search.service';

@Component({
  selector: 'app-multi-select-grid',
  templateUrl: './multi-select-grid.component.html',
  styleUrls: ['./multi-select-grid.component.scss']
})
export class MultiSelectGridComponent extends BaseWrapperDirective implements OnInit {

  @Input() columns: string[] = [];
  @Input() rows: MultiSelectGridItem[] = [];
  @Input() canSearch: boolean = false;
  @Output() selectionChange = new EventEmitter<MultiSelectGridItem>();

  searchControl = new UntypedFormControl();

  searchValue$: string;
  filteredItems$: MultiSelectGridItem[];
  loading: boolean;
  expandedRow: MultiSelectGridItem;

  constructor(private searchService: SearchService) {
    super();
  }

  ngOnInit(): void {
    this.filteredItems$ = this.allRows;

    this.subs.sink = this.searchService.search$.pipe(
      debounceTime(300),
      tap((value) => this.searchValue$ = value),
      tap(() => this.filterItems())
    ).subscribe();
  }

  filterItems() {
    this.loading = true;
    this.filteredItems$ = this.allRows.filter(x => x.value.toLowerCase().includes(this.useableSearchValue));
    setTimeout(() => {
      this.loading = false;
    }, 200);
  }

  get useableSearchValue() {
    return this.searchValue$?.toLowerCase() || '';
  }

  get allRows(): MultiSelectGridItem[] {
    return structuredClone(this.rows);
  }

  toggleRow(row: MultiSelectGridItem) {
    row.isExpanded = !row.isExpanded;
  }

  update(row: MultiSelectGridItem) {
    this.selectionChange.emit(row);
  }

}
